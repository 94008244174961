@import "../../../../assets/styles/variables";

.layout {
  border: 2px solid red;
}

.container {
  display: flex;
  flex-direction: column;
}

.backButton {
  background-color: #f0f2f2;
  padding: 36px 24px 12px 24px;
}

.subtitle {
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
  color: rgba(33, 48, 59, 0.6);
  padding-bottom: 32px;
  padding-top: 0px;
  margin: 0;
}

.link {
  font-weight: 700;
  text-decoration: underline;
  color: rgba(33, 48, 59, 0.6);
  cursor: pointer;
}

.forgotten {
  float: right;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  row-gap: 2px;

  div.showError {
    display: inline-block;
    width: auto;
    font-size: 0.75rem;
    line-height: 18px;
    color: $trula-smoke;
    margin-top: 4px;
  }

  div.hideError {
    display: none;
  }
}

.input {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 24px;
  flex-grow: 1;
  background: $white;
  border: 1px solid $light-smoke;
  border-radius: 40px;
  padding: 8px;
  margin-bottom: 12px;
  outline: none;

  &:focus {
    outline: 2px solid var(--color-blue-800);
    outline-offset: -2px;
  }

  &.invalid {
    border: 1px solid $danger;
    background: $white;
    color: $trula-black;
    outline: none;
  }

  &::placeholder {
    color: #212121;
    opacity: 0.3;
  }

  :-ms-input-placeholder {
    color: #212121;
    opacity: 0.3;
  }

  ::-ms-input-placeholder {
    color: #212121;
    opacity: 0.3;
  }
}

label {
  font-family: $gt-america-bold;
  font-size: 0.875rem;
  line-height: 20px;
  color: $trula-smoke;
  margin-bottom: 4px;
}

.labelContainer {
  display: flex;
  flex-direction: row;
}

.loginButton {
  width: 100%;
  background-color: #f0f2f2;
  padding: 12px 24px;
  position: relative;
  bottom: 24px;
  display: flex;
  justify-content: center;
  margin-top: 36px;
}
