.containerWrapper {
  height: 100vh;
  background-size: cover;

  &.bg-1 {
    background-image: url('https://res.cloudinary.com/ddogkzysg/image/upload/q_49/v1696577723/sign-in/first.jpg');

    @media (max-width: 440px) {
      background-image: url('https://res.cloudinary.com/ddogkzysg/image/upload/q_49/v1696577723/sign-in/first-small.jpg');
    }
  }

  &.bg-2 {
    background-image: url('https://res.cloudinary.com/ddogkzysg/image/upload/q_49/v1696577723/sign-in/second.jpg');

    @media (max-width: 440px) {
      background-image: url('https://res.cloudinary.com/ddogkzysg/image/upload/q_49/v1696577723/sign-in/second-small.jpg');
    }
  }

  &.bg-3 {
    background-image: url('https://res.cloudinary.com/ddogkzysg/image/upload/q_49/v1696577723/sign-in/third.jpg');

    @media (max-width: 440px) {
      background-image: url('https://res.cloudinary.com/ddogkzysg/image/upload/q_49/v1696577723/sign-in/third-small.jpg');
    }
  }

  &.bg-4 {
    background-image: url('https://res.cloudinary.com/ddogkzysg/image/upload/q_49/v1696577723/sign-in/fourth.jpg');

    @media (max-width: 440px) {
      background-image: url('https://res.cloudinary.com/ddogkzysg/image/upload/q_49/v1696577723/sign-in/fourth-small.jpg');
    }
  }

  &.bg-5 {
    background-image: url('https://res.cloudinary.com/ddogkzysg/image/upload/q_49/v1696577723/sign-in/fifth.jpg');

    @media (max-width: 440px) {
      background-image: url('https://res.cloudinary.com/ddogkzysg/image/upload/q_49/v1696577723/sign-in/fifth-small.jpg');
    }
  }
}

.container {
  padding: var(--spacing-4x) var(--spacing-2x);
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4x);
  max-width: 448px;
  min-width: 375px;
  margin: auto;

  @media (max-width: 448px) {
    max-width: unset;
  }
}

.contentContainer {
  flex: 1;
  position: relative;
}

.content {
  position: absolute;
  inset: 0;
  overflow: auto;
  -webkit-appearance: none;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &::-webkit-scrollbar {
    display: none;
  }

  h1, p {
    text-shadow: 2px 2px 4px #b0b0b0;
    text-align: center;
  }

  button {
    width: 100%;
  }
}

.logo {
  background-image: url('https://res.cloudinary.com/ddogkzysg/image/upload/v1696579815/common/logo_kusnll.png');
  width: 80px;
  height: 43px;
  background-size: cover;
}

.loginBtn {
  background-color: var(--color-blue-800) !important;

  p {
    color: white;
    text-shadow: none;
  }
}

.createBtn {
  background-color: white !important;

  p {
    color: var(--color-blue-800);
    text-shadow: none;
  }
}
