@import "./assets/styles/variables";
@import "./assets/styles/mixins";

html {
  height: 100%;
  font-size: 100%;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
}

body {
  letter-spacing: 0;
  height: 100%;
  font-family: $gt-america, sans-serif;
  color: $trula-black;
  background: var(--color-gray-200);
  padding: 0;
  margin: 0;
}

body #webpack-dev-server-client-overlay {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.overlay {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(191, 212, 219, 0.5);
}

.fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

// full calendar overrides
.fc {
  .fc-toolbar-title {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
  }

  .fc-button {
    padding: 4px;
  }

  .fc-event-title {
    text-align: center;
  }

  .fc-day-today {
    background-color: inherit !important;
  }

  .fc-event-main-frame {
    align-items: center;
  }

  .fc-button {
    appearance: none;
    cursor: pointer;

    &:focus,
    &:active {
      outline: none !important;
      box-shadow: unset !important;
    }
  }

  .fc-col-header-cell-cushion {
    font-size: 14px;
  }

  .fc-toolbar.fc-header-toolbar {
    margin-bottom: var(--spacing-2x);
  }
}
